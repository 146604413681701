import React, { ReactElement } from "react";
import parse from "html-react-parser";
import whiteLabelInformation from "../../../util/whitelabel";

import "./PrivacyComponent.css";

const PrivacyComponent = (): ReactElement | null => {
  const {
    privacy: { readMore, readMoreUnderlined, privacyStatementURL },
    privacyModal: { title, body, subText, subTextUnderlined },
    modalCloseButton,
  } = whiteLabelInformation;

  const isVisible = readMore && readMoreUnderlined && title && body && subText && subTextUnderlined && modalCloseButton;

  const privacyURL = privacyStatementURL !== "" ? privacyStatementURL : window.location;

  return isVisible ? (
    <div className="PrivacyComponentWrapper">
      <div className="PrivacyComponent">
        <p>
          {parse(readMore)}
          <a href={privacyURL}>{parse(readMoreUnderlined)}</a>
        </p>
      </div>
    </div>
  ) : null;
};

export default PrivacyComponent;
