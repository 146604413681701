import React, { ReactElement } from "react";
import whiteLabelInformation from "../../../util/whitelabel";

import "./LandingPageLogos.css";

const LandingPageLogos = (): ReactElement => {
  const {
    imagePaths: { firstLogoPath, secondLogoPath },
  } = whiteLabelInformation;

  // Shows the logo's based on the paths in the whitelabel JSON. If the path's empty, it's not shown.
  return (
    <div className="LandingPageLogos">
      {firstLogoPath !== "" && <img src={firstLogoPath} className="LandingPageLogo" id="FirstLogo" alt="First logo" />}
      {secondLogoPath !== "" && <img src={secondLogoPath} className="LandingPageLogo" id="SecondLogo"  alt="Second logo" />}
    </div>
  );
};

export default LandingPageLogos;
