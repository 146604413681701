import parse from "html-react-parser";
import React, { ReactElement } from "react";
import Modal from "react-modal";
import useAppContext from "../../../util/helper";
import { ActionType } from "../../../util/state/reducer";
import whiteLabelInformation from "../../../util/whitelabel";
import closeIcon from "../../../assets/img/close-icon.svg";
import "./VideoPlayer.css";
import "./plyr.css";
// import Plyr from "plyr";

const VideoPlayer = (): ReactElement => {
  const { state, dispatch } = useAppContext();
  const { modalCloseButton } = whiteLabelInformation;
  const { videoPayload, currentlySpeaking} = state;

  const handleCloseButtonClicked = () => {
    // Toggles the Mobile Tutorial Modal Visible/Hidden state
    dispatch({ type: ActionType.CLEAR_VIDEOPLAYER_PAYLOAD });
  };
  // Note: For know youtube only, should be build out.
  if (videoPayload !== undefined && videoPayload.url && !currentlySpeaking) {
    const videoDescription = videoPayload["video-description"] || "";
    return (
      <Modal isOpen={true} onRequestClose={handleCloseButtonClicked} className="Modal" overlayClassName="ModalOverlay">
        <div className="CloseContainer">
          <img className="CloseIcon" src={closeIcon} alt="Close Icon" onClick={handleCloseButtonClicked} />
        </div>
        <div className="plyr__video-embed" id="player">
          <iframe
            src={videoPayload.url}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            id="youtubeIFrame"
          ></iframe>
        </div>
        <p className="MobileTutorialModalBody">{parse(videoDescription)}</p>
        <button className="MobileTutorialModalButton" onClick={handleCloseButtonClicked}>
          {parse(modalCloseButton)}
        </button>
      </Modal>
    );
  }

  return <div />;
};

export default VideoPlayer;
