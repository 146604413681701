import { Dayjs } from "dayjs";
import { TIMEOUT } from "../../config/config";

export interface ActionListResponse {
  type: string;
  label: string;
  highlighted?: boolean;
  "override-background-color-hex"?: string;
  "override-font-color-hex"?: string;
}
export interface ActionListHeader extends ActionListResponse {}

export interface ActionListResponseQuestion extends ActionListResponse {}

export interface ActionListResponseLink extends ActionListResponse {
  url: string;
  highlighted?: boolean;
  alt?: string;
  "icon-name"?: string;
  "icon-prefix"?: string;
}
export interface Payload {
  url: string;
}
export interface VideoPayload extends Payload {
  url: string;
  "video-name": string;
  "video-description": string;
}
export interface AppState {
  sending: boolean;
  recording: boolean;
  permissionModalVisible: boolean;
  settingsModalVisible: boolean;
  privacyModalVisible: boolean;
  closeModalVisible: boolean;
  errorModalVisible: boolean;
  errorModalTitle: string;
  errorModalBody: string;
  closeModalTitle: string;
  closeModalSubtitle: string;
  closeModalBody: string;
  closeModalHasTimer: boolean;
  timeLeft: number;
  timedOut: boolean;
  timeoutMessageVisible: boolean;
  landingPageVisible: boolean;
  landingPageAdditionalPrivacyVisible: boolean;
  mobileMenuVisible: boolean;
  mobileContactModalVisible: boolean;
  mobileTutorialModalVisible: boolean;
  unavailable: boolean;
  ready: boolean;
  sessionEnded: boolean;
  permissionAllowed: boolean;
  spaceBarTutorialVisible: boolean;
  spaceBarTutorialShown: boolean;
  question: string;
  lastUtterance: string;
  actionListResponses: Array<ActionListResponseLink | ActionListResponseQuestion>;
  recordStart?: Dayjs;
  devices: Record<string, unknown>;
  selectedDevice?: any;
  videoPayload: VideoPayload | undefined;
  currentlySpeaking: boolean;
}

const initialState: AppState = {
  sending: false,
  recording: false,
  landingPageVisible: true,
  permissionModalVisible: false,
  settingsModalVisible: false,
  privacyModalVisible: false,
  closeModalVisible: false,
  errorModalVisible: false,
  errorModalTitle: "",
  errorModalBody: "",
  closeModalTitle: "",
  closeModalSubtitle: "",
  closeModalBody: "",
  closeModalHasTimer: false,
  timeLeft: TIMEOUT,
  timeoutMessageVisible: false,
  timedOut: false,
  landingPageAdditionalPrivacyVisible: false,
  mobileMenuVisible: true,
  mobileContactModalVisible: false,
  mobileTutorialModalVisible: false,
  unavailable: true,
  ready: false,
  sessionEnded: false,
  permissionAllowed: false,
  spaceBarTutorialVisible: false,
  spaceBarTutorialShown: false,
  lastUtterance: "",
  question: "",
  actionListResponses: [
    { type: "suggested-question", label: "Wat kan ik hier doen?" },
    { type: "suggested-question", label: "Wat is een vrijwilliger?" },
    { type: "suggested-question", label: "Hoe kan ik mij aanmelden als een vrijwilliger?" },
    { type: "suggested-question", label: "Wat is een mantelzorger?" }
  ],
  recordStart: undefined,
  devices: {},
  selectedDevice: undefined,
  videoPayload: undefined,
  currentlySpeaking: false
};

export default initialState;
