import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import parse from "html-react-parser";
import React, { ReactElement } from "react";
import useAppContext from "../../../util/helper";
import whiteLabelInformation from "../../../util/whitelabel";
import "./ContactBox.css";

const ContactBox = (): ReactElement | null => {
  const { state } = useAppContext();
  const { ready } = state;
  const {
    contact: { title },
  } = whiteLabelInformation;

  const isVisible = title;

  return isVisible ? (
    <div className="ContactBoxWrapper">
      <div className={classNames("ContactBox", { "ContactBox hidden": !ready })}>
        <span className="ContactDetailsTitle">{parse(title)}</span>
        <br />
        <span className="DetailsSpan">
          <FontAwesomeIcon className="icon ContactBoxIcon " icon={["fas", "phone"]} />
          <a href="tel:0882068910">088 - 206 89 10</a>
        </span>
        <span className="DetailsSpan">
          <FontAwesomeIcon className="icon ContactBoxIcon" icon={["fas", "envelope"]} />
          <a href="mailto:service@omring.nl">service@omring.nl</a>
        </span>
      </div>
    </div>
  ) : null;
};

export default ContactBox;
